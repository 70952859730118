import { SHIPPING_TYPE } from "@monorepo/config";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { formatDateTime } from "@monorepo/utils";

export const convertGetAllOrder = (data) =>
  data?.map((item) => ({
    id: item?.id,
    createdAt: formatDateTime(item?.createdAt),
    number: item?.number,
    subTotal: item?.subTotal,
    exportedWarehouse: item?.exportedWarehouse?.name,
    receiverName: item?.receiverContactName,
    orderPriority: item?.orderPriority,
    shippingType: item?.shippingType,
    paymentMethod: item?.paymentMethod,
    status: item?.status,
    createdBy: item?.createdBy,
    fullStockRequired: item?.fullStockRequired,
    remainingDeliverTime: item?.remainingDeliverTime,
    vat: item?.vat,
    receiverContactAddress: item?.receiverContactAddress,
    promisedDeliverTime: item?.promisedDeliverTime,
    shippingUnit: convertShippingUnit(item?.shippingConfiguration, item?.shippingType),
    internalNote: item?.internalNote,
    inStockStatus: item?.stockStatus,
    completedAt: formatDateTime(item?.completedAt),
    fileURLs: item?.fileURLs,
    senderAddress: item?.senderAddress,
    shipperTelephone: item?.shipperTelephone,
    receiverContactPhone: item?.receiverContactPhone,
    note: item?.note,
    total: item?.total,
    customerPaymentMethod: item?.customerPaymentMethod,
    orderType: item?.orderType,
    shippingConfiguration: item?.shippingConfiguration,
    shipperName: item?.shipperName,
    shippingOrderCode: item?.shippingOrderCode,
    items: item?.items,
    codAmount: item?.codAmount,
    shippingVersion: item?.shippingVersion,
  }));

export const convertShippingUnit = (shippingConfiguration, shippingType) => {
  switch (shippingType) {
    case SHIPPING_TYPE.BUS:
      return shippingConfiguration?.busConfig?.busName;
    case SHIPPING_TYPE.DELIVER_3PARTY:
      return shippingConfiguration?.deliver3PartyConfig?.name;
    case SHIPPING_TYPE.PICK_UP_COUNTER:
      return;
  }
};

export const convertGetWarehouse = (data) =>
  data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

export const convertGetCreatedBy = (data) =>
  data?.map((item) => ({
    value: item?.id,
    label: item?.email,
  }));

export const convertPagination = (data) => {
  return {
    total: data?.total,
    pageSize: data?.limit,
    current: data?.offset / data?.limit + 1,
    showSizeChanger: false,
  };
};

export const convertGetCountOrderWithFilter = (data) => ({
  v1waitingForShippingNotSet: data?.v1waitingForShippingNotSet?.count,
  v2waitingForShippingNotSet: data?.v2waitingForShippingNotSet?.count,
  v1waitingForShippingWaiting: data?.v1waitingForShippingWaiting?.count,
  v2waitingForShippingWaiting: data?.v2waitingForShippingWaiting?.count,
  allVerWaitingForShippingWaiting: data?.allVerWaitingForShippingWaiting?.count,
  v1deliveryConfirmPicked: data?.v1deliveryConfirmPicked?.count,
  v2deliveryConfirmPicked: data?.v2deliveryConfirmPicked?.count,
  completed: data?.completed?.count,
});
